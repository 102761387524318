* {
    box-sizing: border-box;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'BrooklynSamuelsFive-Bold';
    src: url('./fonts/font.ttf') format('truetype');
}

.custom-font {
    font-family: 'BrooklynSamuelsFive-Bold';
    font-size: 1.75rem;
    color: teal;
}

.custom-font-2 {
    font-family: 'BrooklynSamuelsFive-Bold';
    font-size: 1.5rem;
    color: #ffc700;
}

.btn-toolbar button {
    border: 1px solid teal;
    color: teal;
}

.btn-toolbar button:hover {
    background-color: teal;
    color: white
}

.btn-toolbar div:last-child {
    margin-left: 30px !important;
}

.btn-selected {
    background-color: teal;
    color: white !important;
}